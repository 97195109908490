/* Import Steampunk Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Goudy+Bookletter+1911&family=Montserrat&family=Roboto+Mono&display=swap');

/* CSS Variables for Consistent Theming */
:root {
  /* Colors */
  --background-color: #1a1a1a;
  --text-color: #d3af6c;
  --heading-color: #e0c97a;
  --link-color: #e6b800;
  --link-hover-color: #ffdd99;
  --button-color: #ffd700;
  --button-bg: linear-gradient(135deg, #6b4f4f, #3a2c2c);
  --button-border: #9a7b4f;
  --button-hover-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6);
  --button-active-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);

  /* Fonts */
  --font-body: 'Goudy Bookletter 1911', serif;
  --font-mono: 'Roboto Mono', monospace;
  --font-heading: 'Cinzel', serif;
  --font-logo: 'Montserrat', sans-serif;

  /* Shadows */
  --text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  --heading-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);

  /* Other */
  --gear-size-large: 50px;
  --gear-size-medium: 30px;
  --gear-size-small: 25px;
}

/* Reset Default Margins and Paddings */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* General Styles */
html, body {
  font-family: var(--font-body);
  background-color: var(--background-color);
  color: var(--text-color);
  scroll-behavior: smooth;
}

body {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

html {
  background-color: var(--background-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}


h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  color: var(--heading-color);
  text-shadow: var(--heading-shadow);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
}

p, li, a {
  line-height: 1.7;
}

/* Links */
a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: var(--link-hover-color);
}

/* Buttons */
button {
  font-family: var(--font-heading);
  color: var(--button-color);
  background: var(--button-bg);
  border: 2px solid var(--button-border);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  padding: 10px 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

button:hover {
  transform: translateY(-3px);
  box-shadow: var(--button-hover-shadow);
}

button:active {
  transform: translateY(0);
  box-shadow: var(--button-active-shadow);
}

/* Steampunk Button Styles */
.steampunk-button {
  font-family: var(--font-heading);
  background: var(--button-bg);
  color: var(--button-color);
  padding: 12px 25px;
  font-size: 1.1em;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.steampunk-button i {
  margin-right: 8px;
  color: var(--button-color);
}

.steampunk-button:hover {
  transform: translateY(-3px);
  box-shadow: var(--button-hover-shadow);
}

.steampunk-button:active {
  transform: translateY(0);
  box-shadow: var(--button-active-shadow);
}


/* Common Heading Styles */
.page-heading {
  font-family: var(--font-logo);
  font-size: 3em;
  color: var(--button-color);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--heading-shadow);
  margin-bottom: 20px;
}

/* Page-Specific Heading Overrides */
.home-heading {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.pickweather-heading {
  font-size: 2.8em;
  color: #00bcd4;
  margin-bottom: 30px;
  letter-spacing: 1.5px;
}

/* Common Text Styles */
.page-text {
  font-size: 1.1em;
  color: #bfa17b;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

/* Page-Specific Text Overrides */
.home-text, .about-text {
  margin-bottom: 30px;
  line-height: 1.6;
}

.home-text {
  font-size: 1.2em;
  max-width: 600px;
  line-height: 1.8;
  color: var(--text-color);
  margin-bottom: 20px;
}

/* Inputs */
.pickweather-input {
  background-color: #3e2d23;
  color: #ffdd99;
  border: 1px solid #8b4513;
  padding: 10px;
  font-family: var(--font-body);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.pickweather-input:focus {
  border-color: #00bcd4;
  outline: none;
}

.pickweather-label {
  font-size: 1.2em;
  color: #ffcc66;
}

/* List Items */
.weather-data li {
  color: var(--text-color);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

/* Globe Image */
.globe-animation {
  border: 2px solid #b08d57;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6), inset 0px 0px 10px rgba(0, 0, 0, 0.6);
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 40px;
  background-color: rgba(0, 0, 0, 0.96);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 100px;
}

@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap'); /* Example fancy font */

/* Navbar Logo Styling */
.navbar-logo {
  font-family: 'MedievalSharp', serif; /* Crazy, fancy font */
  font-size: 2.5em;
  color: var(--button-color);
  text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.7), 0px 0px 15px #ffdd99;
  letter-spacing: 5px;
  text-transform: uppercase;
  animation: breathing-glow 3s infinite ease-in-out; /* Smooth breathing glow */
}

@keyframes breathing-glow {
  0%, 100% {
    text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.7), 0px 0px 15px #ffdd99, 0px 0px 20px #e6b800;
  }
  50% {
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.7), 0px 0px 35px #ffdd99, 0px 0px 45px #e6b800;
  }
}


.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navbar-links li a {
  font-size: 1.1em;
  color: #e0e0e0;
  transition: color 0.3s;
}

.navbar-links li a:hover,
.navbar-links li a.active {
  color: var(--button-color);
}

/* Decorative Gears Container within Navbar */
.gears-container {
  position: absolute;
  bottom: -25%; /* Adjust to bring the border to the center of the gears */
  left: 0;
  width: 100%;
  height: var(--gear-size-large);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  gap: 0;
}

.gear {
  width: var(--gear-size-large);
  height: var(--gear-size-large);
  animation: rotate 8s linear infinite;
  margin-left: -2.5px; /* Adjust this value for more or less overlap */
}


.gear:nth-child(even) {
  animation-direction: reverse;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Main Content Styles */
.content {
  padding: 60px 20px;
}

/* About Page Styles */
.about-container {
  background-size: cover;
  border: 1px solid #604d3b;
  padding: 60px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

/* Images */
.page-image {
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.page-image:hover {
  transform: scale(1.05);
}

/* About Page Image Overrides */
.about-image {
  box-shadow: 0 10px 30px rgba(255, 215, 0, 0.3);
}

/* Home Page Styles */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.9); /* Optional: Adds overlay for text readability */
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}

/* Home Page Image Overrides */
.home-image {
  width: 100%;
  max-width: 300px; /* Reduced size */
  border-radius: 10px;
  margin: 20px 0;
  transition: transform 0.3s ease;
}

.home-image:hover {
  transform: scale(1.05);
}

/* PickWeather Page Styles */
.pickweather-container {
  text-align: center;
  padding: 60px 20px;
  background-color: #1f1f1f;
  color: #fafafa;
  min-height: 100vh;
}

.pickweather-form {
  margin: 0 auto;
  padding: 30px;
  max-width: 500px;
  background-color: #2c2c2c;
  border-radius: 10px;
  box-shadow: 0 8px 25px rgba(0, 188, 212, 0.2);
}

.pickweather-form-group {
  margin-bottom: 20px;
  text-align: left;
}

.pickweather-image-container {
  margin-top: 40px;
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 8px 25px rgba(0, 188, 212, 0.2);
}

.pickweather-subheading {
  font-family: var(--font-logo);
  font-size: 1.5em;
  color: #00bcd4;
  margin-bottom: 15px;
}

/* PickWeather Image Overrides */
.pickweather-image {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.pickweather-image:hover {
  transform: scale(1.02);
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Navbar Adjustments */
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 60px;
    height: auto;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .navbar-links li a {
    font-size: 1em;
  }

  /* Gears Adjustments */
  .gears-container {
    height: var(--gear-size-medium);
  }
  
  .gear {
    width: var(--gear-size-medium);
    height: var(--gear-size-medium);
  }

  .gears-container .gear:nth-child(n+15) {
    display: none;
  }

  /* Heading Adjustments */
  .page-heading {
    font-size: 2em;
  }

  /* Text Adjustments */
  .page-text {
    max-width: 90%;
  }

  /* Image Adjustments */
  .page-image {
    max-width: 300px;
  }

  /* Steampunk Button Adjustments */
  .steampunk-button {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  /* Gears Adjustments */
  .gears-container {
    height: var(--gear-size-small);
  }

  .gear {
    width: var(--gear-size-small);
    height: var(--gear-size-small);
  }

  .gears-container .gear:nth-child(n+12) {
    display: none;
  }

  /* Navbar Adjustments */
  .navbar {
    padding: 15px 15px 50px;
  }

  /* Heading Adjustments */
  .page-heading {
    font-size: 1.8em;
  }

  /* Button Adjustments */
  button, .steampunk-button {
    padding: 8px 16px;
  }
}
/* styles.css */

.blockchain-section {
  border-top: 2px solid #ddd;
  padding-top: 20px;
  margin-top: 20px;
  text-align: center;
}

.blockchain-section h2 {
  margin-bottom: 20px;
}
/* styles.css */

/* styles.css */

/* Container for the Crypto Page */
.crypto-page-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  background-color: rgba(30, 30, 30, 0.85); /* Dark overlay for readability */
  border: 3px solid #bfa17b; /* Steampunk-style border */
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
}

/* Sections within the Crypto Page */
.connect-wallet-section,
.mint-nft-section,
.view-nfts-section {
  border: 2px solid #d3af6c; /* Use steampunk color */
  border-radius: 10px;
  padding: 25px;
  margin: 25px 0;
  background-color: rgba(50, 50, 50, 0.8); /* Semi-transparent background */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Section Headings */
.connect-wallet-section h2,
.mint-nft-section h2,
.view-nfts-section h2 {
  margin-bottom: 20px;
  font-family: var(--font-heading);
  color: var(--heading-color);
  text-shadow: var(--heading-shadow);
}

/* Connect Wallet Button */
.connect-wallet-section button {
  font-family: var(--font-heading);
  color: var(--button-color);
  background: var(--button-bg);
  border: 2px solid var(--button-border);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  padding: 12px 25px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 5px;
}

.connect-wallet-section button:hover {
  transform: translateY(-3px);
  box-shadow: var(--button-hover-shadow);
}

.connect-wallet-section button:active {
  transform: translateY(0);
  box-shadow: var(--button-active-shadow);
}

/* NFT Sections */
.mint-nft-section,
.view-nfts-section {
  background-color: rgba(45, 45, 45, 0.85);
}

/* NFT Cards */
.nft-card {
  background-color: #3e2d23;
  border: 2px solid #d3af6c;
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.nft-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.7);
}

.nft-card img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Transaction Hash Link */
.transaction-hash a {
  color: var(--link-color);
  text-decoration: underline;
}

.transaction-hash a:hover {
  color: var(--link-hover-color);
}

/* Responsive Adjustments for Crypto Page */
@media (max-width: 768px) {
  .crypto-page-container {
    padding: 30px 15px;
  }

  .connect-wallet-section,
  .mint-nft-section,
  .view-nfts-section {
    padding: 20px;
  }

  .nft-card {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .crypto-page-container {
    padding: 20px 10px;
  }

  .connect-wallet-section,
  .mint-nft-section,
  .view-nfts-section {
    padding: 15px;
  }

  .nft-card {
    padding: 10px;
  }
}
