/* src/pages/PickWeather/PickWeather.css */
.pickweather-container {
    font-family: 'Goudy Bookletter 1911', serif;
    color: #f5f5dc;
}

.pickweather-heading {
    text-align: center;
    font-size: 3em;
    margin-bottom: 20px;
    text-shadow: 2px 2px #000;
}

.pickweather-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pickweather-label {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #f5f5dc;
    text-shadow: 1px 1px #000;
}

.pickweather-input {
    font-size: 1em;
    padding: 10px;
    border: 2px solid #8b4513;
    /* SaddleBrown */
    border-radius: 5px;
    background-color: #d2b48c;
    /* Tan */
    color: #000;
    font-family: 'Goudy Bookletter 1911', serif;
    width: 200px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.error-message {
    color: #ff4500;
    /* OrangeRed */
    margin-top: 10px;
    text-shadow: 1px 1px #000;
}

/* Additional steampunk styles */
body {
    background-color: #2c2c2c;
    /* Dark Gray */
}

select,
input {
    /* Additional styling as needed */
}

/* Responsive Design */
@media (max-width: 768px) {
    .pickweather-form {
        padding: 20px;
    }

    .pickweather-heading {
        font-size: 2em;
    }

    .pickweather-input {
        width: 150px;
    }
}
